import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Box,
    Container,
    Stack,
    Table,
    TableBody,
    Typography,
} from "@mui/material";

import EChartsReact from "echarts-for-react";

import {
    COMPARISON_FILTER,
    DISPLAY_ELEMENT_HELPER,
    GB_NotDate,
    GROUP_COUNTRIES,
    GROUP_OFFERS,
    GROUP_ROOM,
    GROUP_TYPE,
    GROUP_VOUCHERS,
    formatDate,
} from "../helpers/filters";
import Colours from "../helpers/colours";

import HoverPaper from "./shared/HoverPaper";

import NoDataRow from "./NoDataRow";
import FiltersMetricSelection from "./FiltersMetricSelection";
import { fns } from "../helpers/common";

export const FiltersChartBarCategory = ({
    data,
    entriesLimit = 5,
    entriesLimitSort = "descending", // or "ascending"
    initialMetric = "bookingsTotal",
    metrics = DISPLAY_ELEMENT_HELPER,
    groupBy,
}) => {
    const js = useSelector((state) => state.globalReportFilters);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [formattedData, setFormattedData] = useState({});
    const [update, setUpdate] = useState(0);
    const [metric, setMetric] = useState(initialMetric);
    const [total, setTotal] = useState(0);

    const [extractedValues, setExtractedValues] = useState({});

    const [numberOfCategories, setNumberOfCategories] = useState(0);

    useEffect(() => {
        if (Object.values(data).length > 0 && GB_NotDate(js).length >= 1) {
            let aux_Extracted_Values = {};
            let aux_total = 0;
            const calculateTop = (useLastYear = false) => {
                let groupedBy = {};
                let sums = {};
                Object.values(data).forEach(
                    ({ keys, result, resultOneYearBefore }) => {
                        if (keys[0].includes("Voucher", 1)) {
                            return;
                        }

                        let aux_r = useLastYear ? resultOneYearBefore : result;
                        const {
                            [metric]: metric_,
                            // Extra metrics
                            voucherMax,
                            voucherMin,
                        } = aux_r ?? {}; //TODO: What happen is metric is not present?
                        if (metric_) {
                            aux_total += useLastYear ? 0 : metric_;
                            const {
                                Month = 1,
                                Year = new Date().getUTCFullYear(),
                                Day = 1,
                                [GROUP_COUNTRIES]: Country = "",
                                [GROUP_OFFERS]: Offers = "",
                                [GROUP_ROOM]: Rooms = "",
                                [GROUP_TYPE]: Type = "",
                                [GROUP_VOUCHERS]: VoucherName = "",
                            } = Object.fromEntries(keys);

                            const key = [
                                Country,
                                Offers,
                                Rooms,
                                Type,
                                VoucherName,
                                Year,
                                Month,
                                Day,
                            ]
                                .filter((e) => (e ?? "").length > 0)
                                .join("/");

                            // TODO: Remove or keep - Asked to remove this from the hover tooltip(It only works for vouchers). If final choice, remove tooltip code as well
                            // HERE TO CALCULATE/ADD EXTRA VALUES
                            // aux_Extracted_Values[key] = {
                            //     min: voucherMin,
                            //     max: voucherMax,
                            // };

                            if (!groupedBy[key]) {
                                groupedBy[key] = [];
                                sums[key] = 0;
                            }

                            groupedBy[key].push([
                                formatDate(new Date(Year, Month - 1, Day)),
                                metric_,
                            ]);

                            sums[key] = sums[key] + metric_;
                        } else {
                        }
                    }
                );
                sums = Object.entries(sums).sort((a, b) =>
                    entriesLimitSort === "ascending" ? a[1] - b[1] : b[1] - a[1]
                );
                let top = sums.slice(0, entriesLimit);

                let topN = {};
                top.map(([k, v]) => {
                    topN[k] = groupedBy[k]; // Copy a Top key to tops
                    delete groupedBy[k]; // Delete that key from the source
                });

                // Calculate Others
                let Others = {};
                if (Object.keys(groupedBy).length > 0) {
                    Object.values(groupedBy).map((arr) => {
                        arr.map((subArr) => {
                            if (!Others[subArr[0]]) Others[subArr[0]] = 0;
                            Others[subArr[0]] = Others[subArr[0]] + subArr[1];
                        });
                    });
                    topN["Others"] = Object.entries(Others);
                    // .sort((a, b) =>
                    //     entriesLimitSort === "ascending"
                    //         ? a[0].localeCompare(b[0])
                    //         : b[0].localeCompare(a[0])
                    // );
                }
                return topN;
            };

            const topN = calculateTop();
            const lastYear = calculateTop(true);

            const allKeys = Array.from(
                new Set([...Object.keys(topN), ...Object.keys(lastYear)])
            );

            const seriesDataThisYear = allKeys.map((key) => {
                const data = topN[key];
                return data ? data.reduce((acc, curr) => curr[1] + acc, 0) : 0;
            });
            const seriesDataLastYear = allKeys.map((key) => {
                const data = lastYear[key];
                return data ? data.reduce((acc, curr) => curr[1] + acc, 0) : 0;
            });

            let comparison =
                COMPARISON_FILTER[js.filters.Comparison ?? "previousYear"]
                    .label;

            const series = [
                {
                    name: comparison ?? "Previous year",
                    type: "bar",
                    data: seriesDataLastYear,
                    itemStyle: { color: "grey" },
                    lineStyle: { color: "grey" },
                    symbolStyle: { color: "grey" },
                    label: {
                        show: true,
                        position: "top",
                        formatter: (params) => {
                            // console.log(params);

                            return fns(hotelID, params.value, {});
                        },
                        fontSize: 10,
                        color: "black",
                    },
                },
                {
                    name: "Current Period",
                    type: "bar",
                    data: seriesDataThisYear,
                    itemStyle: { color: "black" },
                    lineStyle: { color: "black" },
                    symbolStyle: { color: "black" },
                    label: {
                        show: true,
                        position: "top",
                        formatter: (params) => {
                            // console.log(params);

                            return fns(hotelID, params.value, {});
                        },
                        fontSize: 10,
                        color: "black",
                    },
                },
            ];
            setFormattedData({
                xAxisData: allKeys,
                series,
            });
            setTotal(aux_total);
            setUpdate(update + 1);
            setExtractedValues(aux_Extracted_Values);

            setNumberOfCategories(Object.keys(aux_Extracted_Values).length);
        }
    }, [data, metric]); //, js

    useEffect(() => {
        setMetric(initialMetric);
    }, [metrics, initialMetric]);

    const formatAxisLabel = (value) => {
        let words = value.split(" ");
        let lines = [];
        let currentLine = "";

        for (let i = 0; i < words.length; i++) {
            if ((currentLine + words[i]).length <= 15) {
                if (currentLine !== "") {
                    currentLine += " ";
                }
                currentLine += words[i];
            } else {
                lines.push(currentLine);
                currentLine = words[i];
            }
        }

        if (currentLine !== "") {
            lines.push(currentLine);
        }

        return lines.join("\n");
    };

    const formatLabel = (label, standalone) => {
        switch (label) {
            case "OfferTitle":
                label = "RatePlan";
                break;
            case "RoomName":
                label = "RoomType";
                break;
            case "VoucherName":
                label = "Voucher";
                break;
            default:
                break;
        }

        let labelArray = label.split(/(?=[A-Z])/);

        let formattedLabel =
            (standalone
                ? labelArray[0].charAt(0).toUpperCase() +
                  labelArray[0].slice(1).toLowerCase()
                : labelArray[0].toLowerCase()) +
            (labelArray.length > 1 ? " " + labelArray[1].toLowerCase() : "");

        return formattedLabel;
    };

    if (GB_NotDate(js).length >= 1)
        return (
            <Stack>
                <Container
                    maxWidth={false}
                    component={HoverPaper}
                    sx={{ p: 2, backgroundColor: Colours.notificationCard }}
                >
                    <Box>
                        <Stack direction="row" alignItems="baseline">
                            <FiltersMetricSelection
                                metric={metric}
                                metrics={metrics}
                                setMetric={setMetric}
                            />
                            <Typography>{`by ${formatLabel(
                                groupBy[0],
                                false
                            )}`}</Typography>
                        </Stack>
                    </Box>
                    <br />
                    <Stack
                        direction="row"
                        // justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography>
                            Total:{" "}
                            <span style={{ fontWeight: "bold" }}>
                                {fns(
                                    hotelID,
                                    total,
                                    metrics[metric].format ?? {}
                                )}
                            </span>
                        </Typography>
                        {/* <Typography pr={2} pt={2} variant="caption">
                            Comparison is versus the same period last year
                        </Typography> */}
                    </Stack>
                    {Object.values(data).length > 0 ? (
                        <EChartsReact
                            key={update}
                            option={{
                                tooltip: {
                                    trigger: "axis",
                                    formatter: function (params) {
                                        let tooltipHtml =
                                            '<div style="border-collapse: collapse;">';
                                        tooltipHtml += `<div style="margin-bottom: 5px;"><strong>${params[0].name}</strong></div>`;

                                        params.forEach(function (item) {
                                            tooltipHtml +=
                                                '<div style="display: flex; justify-content: space-between; padding: 0px;">';
                                            tooltipHtml += `<div style="padding-left:3px;">${item.marker}</div>`;
                                            tooltipHtml += `<div style="flex: 1; text-align: left; padding-left:4px;">${item.seriesName}</div>`;
                                            tooltipHtml += `<div style="padding-left:15px;"><strong>${fns(
                                                hotelID,
                                                item.value,
                                                {}
                                            )}</strong></div>`;
                                            tooltipHtml += "</div>";
                                        });

                                        // -------
                                        // EXTRAS:
                                        // -------
                                        if (
                                            extractedValues[
                                                (params[1] ?? params[0]).name
                                            ] &
                                            (groupBy[0] === "VoucherName")
                                        ) {
                                            // Min value
                                            let minValue =
                                                extractedValues[
                                                    (params[1] ?? params[0])
                                                        .name // [1] is current year
                                                ].min ?? Number.NaN;
                                            if (!Number.isNaN(minValue)) {
                                                tooltipHtml +=
                                                    '<div style="display: flex; justify-content: space-between; padding: 0px;">';

                                                tooltipHtml += `<div style="display: flex; align-items: center; justify-content: center;">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" >
                                                    <path d="m20 12-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8z"></path>
                                                </svg>
                                            </div>`;
                                                tooltipHtml += `<div style="flex: 1; text-align: left; padding-left:5px;">${"Min:"}</div>`;
                                                tooltipHtml += `<div style="padding-left:15px;"><strong>${fns(
                                                    hotelID,
                                                    minValue,
                                                    {}
                                                    // { left: "currency" }
                                                )}</strong></div>`;
                                                tooltipHtml += "</div>";
                                            }

                                            // Max value
                                            let maxValue =
                                                extractedValues[
                                                    (params[1] ?? params[0])
                                                        .name // [1] is current year
                                                ].max ?? Number.NaN;
                                            if (!Number.isNaN(minValue)) {
                                                tooltipHtml +=
                                                    '<div style="display: flex; justify-content: space-between; padding: 0px;">';
                                                tooltipHtml += `<div style="display: flex; align-items: center; justify-content: center;">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" >
                                                    <path d="m4 12 1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8z"></path>
                                                </svg>
                                            </div>`;
                                                tooltipHtml += `<div style="flex: 1; text-align: left; padding-left:5px;">${"Max:"}</div>`;
                                                tooltipHtml += `<div style="padding-left:15px;"><strong>${fns(
                                                    hotelID,
                                                    maxValue,
                                                    {}
                                                )}</strong></div>`;
                                                tooltipHtml += "</div>";
                                            }
                                        }

                                        // ------
                                        tooltipHtml += "</div>";
                                        return tooltipHtml;
                                    },
                                },
                                legend: { show: false },
                                xAxis: {
                                    type: "category",
                                    data: formattedData.xAxisData,
                                    axisLabel: {
                                        interval: 0,
                                        textStyle: {
                                            fontWeight: "light",
                                        },
                                        // overflow: "truncate",
                                        // width: `${100 / numberOfCategories}%`,
                                        // rotate: 45,
                                        // formatter: function (value) {
                                        //     return value.length > 10
                                        //         ? value.slice(0, 10) + "..."
                                        //         : value;
                                        // },
                                        formatter: function (value) {
                                            return formatAxisLabel(value);
                                        },
                                    },
                                },
                                yAxis: {
                                    name: (metrics[metric] ?? {}).short ?? "",
                                    nameLocation: "middle",
                                    nameGap: 50,
                                    nameTextStyle: {
                                        fontSize: 14,
                                    },

                                    axisLabel: {
                                        formatter: function (value) {
                                            if (value >= 1000) {
                                                return `${value / 1000}k`;
                                            }
                                            return value;
                                        },
                                    },
                                },

                                series: formattedData.series,
                            }}
                        />
                    ) : (
                        <Table>
                            <TableBody>
                                <NoDataRow text="No data for these filters" />
                            </TableBody>
                        </Table>
                    )}
                </Container>
            </Stack>
        );
    return null;
};

export default FiltersChartBarCategory;
