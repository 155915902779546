import ExcelJS from "exceljs";

import { get_currency } from "./currency";
import { COMPARISON_FILTER } from "./filters";

export const fullExport = (config, data, hotelID) => {
    const { fromDay, toDay, dataType, filters } = config;

    // console.log(filters.Comparison);

    let comparison_label =
        COMPARISON_FILTER[filters.Comparison ?? "previousYear"].label;

    // console.log(comparison_label, filters.Comparison);

    let currencySymbol = get_currency(hotelID);

    let processedData = process(data);

    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet("Full data export");

    ws.addRow(["Full data export"]).font = { size: 16 };
    ws.addRow(["Date", `${fromDay} - ${toDay}`]);
    ws.addRow(["Data type", `${dataType}`]);

    ws.addRow([]);

    // Add filters

    // ws.addRow(["Filter key", "Filter value"]);

    // Object.entries(filters).map(([key, value]) => {
    //     if (Array.isArray(value)) {
    //         if (value.length === 0) {
    //             return;
    //         }

    //         const arrayValues = value.join(", ");

    //         ws.addRow([`${key}`, `${arrayValues}`]);
    //     } else {
    //         ws.addRow([`${key}`, `${value}`]);
    //     }
    // });

    const renameKey = (key) => {
        switch (key) {
            case "":
                return "Total";
            case "OfferTitle":
                return "Rate plan";
            case "RoomName":
                return "Room type";
            case "Country":
                return "Country";
            case "VoucherName":
                return "Vouchers";
            default:
                return key;
        }
    };

    Object.entries(processedData).map(([key, object], _) => {
        ws.addRow();

        // Add the header row with column headers
        const headerRow = ws.addRow([
            renameKey(key),

            "Bookings",
            "+/- (%)",
            `${comparison_label}`,

            "Room Nights",
            "+/- (%)",
            `${comparison_label}`,

            "Room Revenue",
            "+/- (%)",
            `${comparison_label}`,

            "Avg. length of stay",
            "+/- (%)",
            `${comparison_label}`,

            "ADR",
            "+/- (%)",
            `${comparison_label}`,

            "Avg. lead time",
            "+/- (%)",
            `${comparison_label}`,

            "Cancelled Reservations",
            "+/- (%)",
            `${comparison_label}`,
        ]);

        // Style the header row
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FF0070C0" },
            };
            cell.font = {
                color: { argb: "FFFFFFFF" },
            };
        });

        // Initialize totals
        let totals = {
            bookingsTotal: 0,
            bookingsTotalYearBefore: 0,
            roomNightsTotal: 0,
            roomNightsTotalYearBefore: 0,
            revenueTotal: 0,
            revenueTotalYearBefore: 0,
            ALoSTotal: 0,
            ALoSTotalYearBefore: 0,
            ADRTotal: 0,
            ADRTotalYearBefore: 0,
            ABWTotal: 0,
            ABWTotalYearBefore: 0,
            bookingsCancelled: 0,
            bookingsCancelledYearBefore: 0,
        };

        object.forEach(
            ({
                name,
                bookingsTotal,
                bookingsTotalYearBefore,
                roomNightsTotal,
                roomNightsTotalYearBefore,
                revenueTotal,
                revenueTotalYearBefore,
                ALoSTotal,
                ALoSTotalYearBefore,
                ADRTotal,
                ADRTotalYearBefore,
                ABWTotal,
                ABWTotalYearBefore,
                bookingsCancelled,
                bookingsCancelledYearBefore,
            }) => {
                if (
                    name === "Voucher" ||
                    (key === "VoucherName" && name === "")
                ) {
                    return;
                }

                // Update totals
                totals.bookingsTotal += isNaN(bookingsTotal)
                    ? 0
                    : bookingsTotal;
                totals.bookingsTotalYearBefore += isNaN(bookingsTotalYearBefore)
                    ? 0
                    : bookingsTotalYearBefore;
                totals.roomNightsTotal += isNaN(roomNightsTotal)
                    ? 0
                    : roomNightsTotal;
                totals.roomNightsTotalYearBefore += isNaN(
                    roomNightsTotalYearBefore
                )
                    ? 0
                    : roomNightsTotalYearBefore;
                totals.revenueTotal += isNaN(revenueTotal) ? 0 : revenueTotal;
                totals.revenueTotalYearBefore += isNaN(revenueTotalYearBefore)
                    ? 0
                    : revenueTotalYearBefore;
                totals.ALoSTotal += isNaN(ALoSTotal) ? 0 : ALoSTotal;
                totals.ALoSTotalYearBefore += isNaN(ALoSTotalYearBefore)
                    ? 0
                    : ALoSTotalYearBefore;
                totals.ADRTotal += isNaN(ADRTotal) ? 0 : ADRTotal;
                totals.ADRTotalYearBefore += isNaN(ADRTotalYearBefore)
                    ? 0
                    : ADRTotalYearBefore;
                totals.ABWTotal += isNaN(ABWTotal) ? 0 : ABWTotal;
                totals.ABWTotalYearBefore += isNaN(ABWTotalYearBefore)
                    ? 0
                    : ABWTotalYearBefore;
                totals.bookingsCancelled += isNaN(bookingsCancelled)
                    ? 0
                    : bookingsCancelled;
                totals.bookingsCancelledYearBefore += isNaN(
                    bookingsCancelledYearBefore
                )
                    ? 0
                    : bookingsCancelledYearBefore;

                const dataRow = ws.addRow([
                    name,

                    isNaN(bookingsTotal) ? 0 : bookingsTotal,
                    isNaN(bookingsTotalYearBefore)
                        ? 0
                        : calcPercentage(
                              bookingsTotal,
                              bookingsTotalYearBefore
                          ),
                    isNaN(bookingsTotalYearBefore)
                        ? 0
                        : bookingsTotalYearBefore,

                    isNaN(roomNightsTotal) ? 0 : roomNightsTotal,
                    isNaN(roomNightsTotalYearBefore)
                        ? 0
                        : calcPercentage(
                              roomNightsTotal,
                              roomNightsTotalYearBefore
                          ),
                    isNaN(roomNightsTotalYearBefore)
                        ? 0
                        : roomNightsTotalYearBefore,

                    isNaN(revenueTotal)
                        ? 0
                        : `${currencySymbol}${revenueTotal}`,
                    isNaN(revenueTotalYearBefore)
                        ? 0
                        : calcPercentage(revenueTotal, revenueTotalYearBefore),
                    isNaN(revenueTotalYearBefore)
                        ? 0
                        : `${currencySymbol}${revenueTotalYearBefore}`,

                    isNaN(ALoSTotal) ? 0 : ALoSTotal,
                    isNaN(ALoSTotalYearBefore)
                        ? 0
                        : calcPercentage(ALoSTotal, ALoSTotalYearBefore),
                    isNaN(ALoSTotalYearBefore) ? 0 : ALoSTotalYearBefore,

                    isNaN(ADRTotal) ? 0 : `${currencySymbol}${ADRTotal}`,
                    isNaN(ADRTotalYearBefore)
                        ? 0
                        : calcPercentage(ADRTotal, ADRTotalYearBefore),
                    isNaN(ADRTotalYearBefore)
                        ? 0
                        : `${currencySymbol}${ADRTotalYearBefore}`,

                    isNaN(ABWTotal) ? 0 : ABWTotal,
                    isNaN(ABWTotalYearBefore)
                        ? 0
                        : calcPercentage(ABWTotal, ABWTotalYearBefore),
                    isNaN(ABWTotalYearBefore) ? 0 : ABWTotalYearBefore,

                    isNaN(bookingsCancelled) ? 0 : bookingsCancelled,
                    isNaN(bookingsCancelledYearBefore)
                        ? 0
                        : calcPercentage(
                              bookingsCancelled,
                              bookingsCancelledYearBefore
                          ),
                    isNaN(bookingsCancelledYearBefore)
                        ? 0
                        : bookingsCancelledYearBefore,
                ]);

                function formatCell(cell, value, index) {
                    cell.alignment = {
                        vertical: "middle",
                        // horizontal: "center",
                    };

                    if (![3, 6, 9, 12, 15, 18, 21].includes(index)) return;

                    let fillColor;
                    if ([3, 6, 9, 12, 15].includes(index)) {
                        fillColor =
                            value < 0
                                ? "FFF2C6DE"
                                : value > 0
                                ? "FFC9E4DE"
                                : "FFFAEDCB";
                    } else if ([18, 21].includes(index)) {
                        fillColor =
                            value < 0
                                ? "FFC9E4DE"
                                : value > 0
                                ? "FFF2C6DE"
                                : "FFFAEDCB";
                    }

                    cell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: fillColor },
                    };
                }

                dataRow.eachCell((cell, index) => {
                    let value = cell.value;
                    formatCell(cell, value, index);
                });
            }
        );

        if (renameKey(key) !== "Total") {
            // Add totals row
            const totalsRow = ws.addRow([
                "Total",
                totals.bookingsTotal,
                calcPercentage(
                    totals.bookingsTotal,
                    totals.bookingsTotalYearBefore
                ),
                totals.bookingsTotalYearBefore,

                totals.roomNightsTotal,
                calcPercentage(
                    totals.roomNightsTotal,
                    totals.roomNightsTotalYearBefore
                ),
                totals.roomNightsTotalYearBefore,

                `${currencySymbol}${totals.revenueTotal}`,
                calcPercentage(
                    totals.revenueTotal,
                    totals.revenueTotalYearBefore
                ),
                `${currencySymbol}${totals.revenueTotalYearBefore}`,

                totals.ALoSTotal,
                calcPercentage(totals.ALoSTotal, totals.ALoSTotalYearBefore),
                totals.ALoSTotalYearBefore,

                `${currencySymbol}${totals.ADRTotal}`,
                calcPercentage(totals.ADRTotal, totals.ADRTotalYearBefore),
                `${currencySymbol}${totals.ADRTotalYearBefore}`,

                totals.ABWTotal,
                calcPercentage(totals.ABWTotal, totals.ABWTotalYearBefore),
                totals.ABWTotalYearBefore,

                totals.bookingsCancelled,
                calcPercentage(
                    totals.bookingsCancelled,
                    totals.bookingsCancelledYearBefore
                ),
                totals.bookingsCancelledYearBefore,
            ]);

            // Style the totals row
            totalsRow.eachCell((cell) => {
                cell.font = {
                    bold: true,
                };
            });
        }
    });

    resizeColumns(ws);

    createBlob(wb);
};

// Resize all columns in the worksheet

const resizeColumns = (ws) => {
    ws.columns.forEach(function (column, i) {
        let maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
            var columnLength = cell.value ? cell.value.toString().length : 12;
            if (columnLength > maxLength) {
                maxLength = columnLength;
            }
        });
        column.width = maxLength < 12 ? 12 : maxLength;
    });
};

// Create blob (to download the Excel export)

const createBlob = (wb) => {
    const cb = async () => {
        const blob = await wb.xlsx.writeBuffer();

        const blobUrl = URL.createObjectURL(
            new Blob([blob], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
        );
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = "FullDataExport.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
    };

    cb();
};

const process = (data) => {
    delete data["Type"];

    const order = {
        "": null,
        OfferTitle: null,
        RoomName: null,
        Country: null,
        VoucherName: null,
    };

    const reordered = Object.assign(order, data);

    let auxProcessedData = {};

    Object.entries(reordered).map(([key, object], _) => {
        auxProcessedData[key] = [];

        Object.entries(object).map(([subKey, objs], _) => {
            let processedObj = { name: subKey, ...objs["result"] };

            // Appending "YearBefore" suffix to keys from objs["resultOneYearBefore"]
            if (objs["resultOneYearBefore"]) {
                Object.keys(objs["resultOneYearBefore"]).forEach((prop) => {
                    processedObj[`${prop}YearBefore`] =
                        objs["resultOneYearBefore"][prop];
                });
            }

            auxProcessedData[key].push(processedObj);
        });

        auxProcessedData[key].sort((a, b) => b.bookings - a.bookings);
    });

    return auxProcessedData;
};

const calcPercentage = (actual, comparison) => {
    if (actual === 0 || comparison === 0) return 0;

    return Math.round(((actual - comparison) / comparison) * 100, 2);
};
